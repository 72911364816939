import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreateUserRequest, ListPartiesRequest } from '@xpanse/authz-service-client';

import { usePartiesApi, useUsersApi } from '@/api/auth-z';
import { IUserProfile } from '@/context/auth/types';
import { useErrorNotification } from '@/hooks/useErrorNotification';

const queryKey = (listPartiesRequest: ListPartiesRequest) => ['users', { ...listPartiesRequest }];

export const useUserProfile = (): IUserProfile => {
  const { user } = useAuth0();

  return user && user['com.xpanse.starter.security.domain.UserProfile'];
};

export const useGetUsers = (listPartiesRequest: ListPartiesRequest) => {
  const partiesApi = usePartiesApi();
  return useQuery({
    queryKey: queryKey(listPartiesRequest),
    queryFn: () => partiesApi.listParties(listPartiesRequest),
  });
};

export const useCreateUsers = (organizationCode: string) => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (users: CreateUserRequest[]) => usersApi.createUsers({ createUserRequest: users }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey({ organizationCode }) }),
    onError: (error) => reportError(error),
  });
};
